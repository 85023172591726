var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasComments
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.get(_vm.lastComment, "author_name")} ${_vm.get(
                    _vm.lastComment,
                    "author_surname"
                  )}`
                ) +
                " | " +
                _vm._s(
                  _vm.formatUtc(
                    _vm.get(_vm.lastComment, "creation_date"),
                    _vm.DATE_FORMAT.defaultExtended,
                    _vm.timezone
                  )
                ) +
                " "
            ),
          ]),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.get(_vm.lastComment, "comment")) + " "),
          ]),
        ])
      : _c("span", [
          _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.notAvailable) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }