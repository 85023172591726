import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/motion-ui/v1';
import { HEADER_TOOLTIPS } from '../const/tooltips.const';
import AlertLink from '../components/AlertLink';
import LastComment from '../components/LastComment';
import StatusBadge from '../components/StatusBadge';
import TooltipHeader from '../../components/TooltipHeader';

export const contentCells = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    title: 'Alert type',
    property: 'alert_name',
    component: AlertLink,
    width: 170,
    props: result => ({ result }),
  },
  {
    title: 'Alert class',
    property: 'alert_class',
    width: 60,
  },
  {
    title: 'Occurred on',
    property: 'occurred_on_ts',
    width: 130,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
  {
    title: 'Status',
    property: 'status',
    component: StatusBadge,
    width: 80,
    props: result => ({ status: result.status }),
  },
  {
    title: 'Station',
    property: 'station',
    width: 80,
  },
  {
    title: 'RA number',
    property: 'rental_agreement_number',
    width: 60,
  },
  {
    title: 'Reg. number',
    property: 'reg_number',
    width: 100,
  },
  {
    title: 'GWY status',
    property: 'gw_status',
    width: 70,
  },
  {
    title: 'Original movement',
    property: 'movement_type',
    width: 60,
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.movementType }),
  },
  {
    title: 'Model',
    property: 'model',
    width: 200,
  },
  {
    title: 'Category',
    property: 'category',
    folded: true,
  },
  {
    title: 'Last comment',
    component: LastComment,
    props: result => ({ comments: result.comments, timezone: operatorTimezone }),
    folded: true,
  },
  {
    title: 'Last update date',
    property: 'last_update',
    displayPriority: 2,
    folded: true,
    width: 120,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },

];

export const alertListFacets = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Alert type',
      attributeName: 'alert_name',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Alert class',
      attributeName: 'alert_class',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Status',
      attributeName: 'status',
      sortFacetsBy: ['count:desc', 'name:asc'],
      transformValue: sentenceCase,
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Station',
      attributeName: 'station',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'RA number',
      attributeName: 'rental_agreement_number',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'GWY status',
      attributeName: 'gw_status',
      placeholder: 'Select gwy status',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Original movement',
      attributeName: 'movement_type',
      sortFacetsBy: ['count:desc', 'name:asc'],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.dateRange,
    props: {
      title: 'Occurred on',
      attributeName: 'occurred_on_ts',
      timezone: operatorTimezone,
    },
  },
];
