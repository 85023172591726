<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { DATE_FORMAT, formatUtc } from '@emobg/web-utils';

export default {
  name: 'LastComment',
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasComments() {
      return !isEmpty(this.comments);
    },
    lastComment() {
      return last(this.comments);
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
    get,
  },
};
</script>

<template>
  <div>
    <div
      v-if="hasComments"
      class="d-flex flex-column"
    >
      <span>
        {{ `${get(lastComment, 'author_name')} ${get(lastComment, 'author_surname')}` }} |
        {{ formatUtc(get(lastComment, 'creation_date'), DATE_FORMAT.defaultExtended, timezone) }}
      </span>
      <span>
        {{ get(lastComment, 'comment') }}
      </span>
    </div>
    <span v-else>
      {{ FALLBACK_MESSAGE.notAvailable }}
    </span>
  </div>
</template>
