var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CollisionMapAndChart col-12",
      attrs: { "data-test-id": "collision_map_chart-component" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "row no-gutters emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-none py-2 px-3",
        },
        [
          _c("div", { staticClass: "col-md-6 pr-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "emobg-border-4 emobg-border-color-ground-lighter emobg-border-radius-none",
              },
              [
                _vm.hasMapData
                  ? [
                      _c("GoogleMapTripsComponent", {
                        ref: "collisionMap",
                        staticClass: "w-100",
                        attrs: {
                          id: "map",
                          "map-type-control": false,
                          "street-view-control": false,
                          "zoom-control": false,
                          "before-path": _vm.mapPaths.before,
                          "after-path": _vm.mapPaths.after,
                          "marker-sources": _vm.getCollisionPoints(
                            _vm.collisionDetails,
                            _vm.mapPaths.before,
                            _vm.mapPaths.after
                          ),
                          "data-test-id": "location_crash-map",
                        },
                      }),
                    ]
                  : _c(
                      "div",
                      {
                        staticClass:
                          "px-auto d-flex justify-content-center align-items-center GoogleMapComponent__holder",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.FALLBACK_MESSAGE.notAvailable) + " "
                        ),
                      ]
                    ),
              ],
              2
            ),
            _c("div", { staticClass: "py-2 emobg-color-ink-light" }, [
              _vm._v(
                " Location of the car 30” before the collision (green) and 30” after the collision (red) "
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm.get(_vm.collisionDetails, "direction")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "emobg-border-4 emobg-border-color-ground-lighter emobg-border-radius-none",
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-4 pt-1 d-flex justify-content-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "p-2 mb-6 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white",
                                {
                                  invisible:
                                    !_vm.inRange(
                                      _vm.collisionDetails.direction,
                                      315,
                                      361
                                    ) &&
                                    !_vm.inRange(
                                      _vm.collisionDetails.direction,
                                      0,
                                      44
                                    ),
                                },
                              ],
                            },
                            [_vm._v(" Front side impact ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-4" }),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-4 d-flex justify-content-start pl-6 my-auto",
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "p-2 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white",
                                {
                                  invisible: !_vm.inRange(
                                    _vm.collisionDetails.direction,
                                    225,
                                    314
                                  ),
                                },
                              ],
                            },
                            [_vm._v(" Left side impact ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "orbit",
                            style: {
                              transform: `rotate(${
                                _vm.collisionDetails.direction + 45
                              }deg)`,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "orbit-cirlce",
                                class: {
                                  disabled: _vm.isNil(
                                    _vm.collisionDetails.direction
                                  ),
                                },
                              },
                              [
                                _c("img", {
                                  style: {
                                    transform: `rotate(-${
                                      _vm.collisionDetails.direction + 45
                                    }deg)`,
                                  },
                                  attrs: {
                                    src: "/carrental/collision-car.svg",
                                    alt: "Collision side",
                                    height: "157px",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-4 d-flex justify-content-end pr-6 my-auto",
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "p-2 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white",
                                {
                                  invisible: !_vm.inRange(
                                    _vm.collisionDetails.direction,
                                    45,
                                    134
                                  ),
                                },
                              ],
                            },
                            [_vm._v(" Right side impact ")]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-4 pb-1 d-flex justify-content-center",
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "p-2 mt-6 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white",
                                {
                                  invisible: !_vm.inRange(
                                    _vm.collisionDetails.direction,
                                    135,
                                    224
                                  ),
                                },
                              ],
                            },
                            [_vm._v(" Rear side impact ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-4" }),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "py-2 emobg-color-ink-light" },
              [
                _vm.collisionDetails &&
                _vm.isNil(_vm.collisionDetails.direction)
                  ? _c("span", { staticClass: "emobg-color-danger" }, [
                      _vm._v(
                        " We could not determine the side where the car was impacted "
                      ),
                    ])
                  : [
                      _vm._v(
                        " Collision bearing (The dot shows the estimated direction of the impact) "
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._m(1),
      _c(
        "div",
        {
          staticClass:
            "row no-gutters emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-none py-3 mt-2 justify-content-center",
        },
        [
          _vm.speedTelemetry.length
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ChartComponent", {
                    staticClass: "w-100 p-2",
                    attrs: {
                      id: `speed${_vm.isPdf ? "-pdf" : ""}`,
                      "chart-options": _vm.speedChartOptions,
                      "data-test-id": "speed-chart",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "py-2 emobg-color-ink-light d-flex justify-content-center",
                    },
                    [
                      _vm._v(
                        " Chart showing the speed over 30 seconds before and after the collision "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "px-auto py-6" }, [
                _vm._v(
                  " We could not get any speed data because the car was not in movement when the accident occurred "
                ),
              ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "emobg-body-2" }, [
          _vm._v(" Location and collision bearing "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-2 mt-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "emobg-body-2" }, [_vm._v(" Speed graph ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }