<script>
import toLower from 'lodash/toLower';
import { sentenceCase } from '@emobg/web-utils';

import { ALERT_CLASSES } from '../const/alertData.const';

export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    badgeColor() {
      return ALERT_CLASSES[toLower(this.status)] || ALERT_CLASSES.fallback;
    },
  },
  methods: { sentenceCase },
};
</script>

<template>
  <div class="emobg-body-1">
    <ui-badge
      v-if="status"
      :color="badgeColor"
    >
      {{ sentenceCase(status) }}
    </ui-badge>
    <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
  </div>
</template>
