var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "AlertsDetailsView position-relative emobg-background-color-ground-lighter py-3 px-2 px-lg-5",
      attrs: { "data-test-id": "alert_details-view" },
    },
    [
      _vm.isAlertDetailsLoading
        ? _c("ui-loader")
        : [
            _c(
              "h1",
              { staticClass: "py-3 d-flex justify-content-between" },
              [
                _c("span", [_vm._v("Alert Detail")]),
                _vm.alertData &&
                _vm.includes(
                  _vm.permissions,
                  _vm.CARRENTAL_PERMISSIONS.alertsChangeStatus
                )
                  ? [
                      !_vm.isAlertClosed
                        ? _c(
                            "ui-button",
                            {
                              attrs: {
                                face: _vm.FACES.outline,
                                loading: _vm.isChangingAlertStatus,
                                "data-test-id": "close-button",
                              },
                              on: { clickbutton: _vm.updateAlertStatus },
                            },
                            [_vm._v(" Close alert ")]
                          )
                        : _vm._e(),
                      _vm.isAlertClosed
                        ? _c(
                            "ui-button",
                            {
                              attrs: {
                                face: _vm.FACES.outline,
                                loading: _vm.isChangingAlertStatus,
                                "data-test-id": "reopen-button",
                              },
                              on: { clickbutton: _vm.updateAlertStatus },
                            },
                            [_vm._v(" Reopen alert ")]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "row emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white mx-0",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-8 p-3 emobg-border-right-1 emobg-border-color-ground-light",
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "my-2 pb-3 emobg-border-bottom-2 emobg-border-color-ground-lighter",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.alertData.name} (${_vm.alertData.class})`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("h3", { staticClass: "pb-2 pt-4" }, [
                      _vm._v(" Vehicle and movement details "),
                    ]),
                    !_vm.isEmpty(_vm.alertData)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "row no-gutters pt-1 pb-4 emobg-border-bottom-2 emobg-border-color-ground-lighter",
                          },
                          [
                            _c("div", { staticClass: "col-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "py-2 emobg-body-2",
                                  attrs: {
                                    "data-test-id": "registration_number-label",
                                  },
                                },
                                [_vm._v(" Registration number ")]
                              ),
                              _c(
                                "p",
                                { staticClass: "mb-3" },
                                [
                                  _c(
                                    "RouterLink",
                                    {
                                      staticClass:
                                        "emobg-link-primary emobg-body-2",
                                      attrs: {
                                        to: {
                                          name: _vm.carrentalRouter.vehicles
                                            .summary.alerts,
                                          params: {
                                            vehicleVin:
                                              _vm.alertPayload.vehicleVin,
                                          },
                                        },
                                        target: "_blank",
                                        "data-test-id":
                                          "license_plate-vehicle_summary_alerts-link",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.alertVehicleData,
                                              "licensePlate"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pb-1 emobg-body-2",
                                  attrs: { "data-test-id": "vin_number-label" },
                                },
                                [_vm._v(" Vin number ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-1 mb-3",
                                  attrs: { "data-test-id": "vin_number-value" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.alertPayload.vehicleVin) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pb-1 emobg-body-2",
                                  attrs: { "data-test-id": "model-label" },
                                },
                                [_vm._v(" Model ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-1",
                                  attrs: { "data-test-id": "model-value" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.alertVehicleData, "model")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "py-2 emobg-body-2",
                                  attrs: { "data-test-id": "gwy_status-label" },
                                },
                                [_vm._v(" GWY status ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  attrs: { "data-test-id": "gwy_status-value" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.alertVehicleData.greenwayStatus ||
                                          _vm.FALLBACK_MESSAGE.noData
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 emobg-body-2",
                                      attrs: {
                                        "data-test-id":
                                          "original_movement-label",
                                      },
                                    },
                                    [_vm._v(" Original movement ")]
                                  ),
                                  _c(
                                    "ui-tooltip",
                                    {
                                      attrs: {
                                        tooltip:
                                          "Original movement: Is the movement of the vehicle when the alert was triggered",
                                      },
                                    },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          color: _vm.GRAYSCALE.inkLight,
                                          size: _vm.ICONS_SIZES.small,
                                          icon: _vm.ICONS.infoFull,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    "data-test-id": "original_movement-value",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.vehicleMovementData,
                                          "type"
                                        ) || _vm.FALLBACK_MESSAGE.noData
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 emobg-body-2",
                                      attrs: {
                                        "data-test-id":
                                          "updated_movement-label",
                                      },
                                    },
                                    [_vm._v(" Updated movement ")]
                                  ),
                                  _c(
                                    "ui-tooltip",
                                    {
                                      attrs: {
                                        tooltip:
                                          "Updated movement: Is the latest movement of the vehicle that we have on the system",
                                      },
                                    },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          color: _vm.GRAYSCALE.inkLight,
                                          size: _vm.ICONS_SIZES.small,
                                          icon: _vm.ICONS.infoFull,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    "data-test-id": "updated_movement-value",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.vehicleMovementData,
                                          "updatedType"
                                        ) || _vm.FALLBACK_MESSAGE.noData
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            !_vm.isEmpty(_vm.vehicleMovementData)
                              ? _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "py-2 emobg-body-2",
                                        attrs: {
                                          "data-test-id": "ra_number-label",
                                        },
                                      },
                                      [_vm._v(" RA number ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          "data-test-id": "ra_number-value",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.get(
                                                _vm.vehicleMovementData,
                                                "rentalAgreementNumber"
                                              ) || _vm.FALLBACK_MESSAGE.noData
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 emobg-body-2" },
                                      [_vm._v(" Pickup ")]
                                    ),
                                    !_vm.get(
                                      _vm.vehicleMovementData,
                                      "startTime"
                                    ) &&
                                    _vm.isEmpty(
                                      _vm.get(
                                        _vm.vehicleMovementData,
                                        "startLocation",
                                        {}
                                      )
                                    )
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "data-test-id":
                                                  "pickup_no_data-label",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FALLBACK_MESSAGE.noData
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _vm.get(
                                            _vm.vehicleMovementData,
                                            "startTime"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    "data-test-id":
                                                      "pickup_date",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatUtc(
                                                          _vm
                                                            .vehicleMovementData
                                                            .startTime,
                                                          _vm.DATE_FORMAT
                                                            .defaultExtended,
                                                          _vm.operatorTimezone
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "data-test-id":
                                                  "pickup_station",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getLocationLabel(
                                                      _vm.get(
                                                        _vm.vehicleMovementData,
                                                        "startLocation"
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                    _c(
                                      "div",
                                      { staticClass: "py-2 emobg-body-2" },
                                      [_vm._v(" Return ")]
                                    ),
                                    !_vm.get(
                                      _vm.vehicleMovementData,
                                      "endTime"
                                    ) &&
                                    _vm.isEmpty(
                                      _vm.get(
                                        _vm.vehicleMovementData,
                                        "endLocation",
                                        {}
                                      )
                                    )
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "data-test-id":
                                                  "return_no_data-label",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FALLBACK_MESSAGE.noData
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                "data-test-id": "return_date",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatUtc(
                                                      _vm.vehicleMovementData
                                                        .endTime,
                                                      _vm.DATE_FORMAT
                                                        .defaultExtended,
                                                      _vm.operatorTimezone
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "data-test-id":
                                                  "return_station",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getLocationLabel(
                                                      _vm.get(
                                                        _vm.vehicleMovementData,
                                                        "endLocation"
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row mb-4" }, [
                      _c("h3", { staticClass: "mt-4 pb-3 col-12" }, [
                        _vm._v(" Alert details "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pt-2 pb-1 emobg-body-2",
                              attrs: { "data-test-id": "alert_status-label" },
                            },
                            [_vm._v(" Alert status ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pt-1 mb-3",
                              attrs: { "data-test-id": "alert_status-value" },
                            },
                            [
                              _c(
                                "ui-badge",
                                { attrs: { color: _vm.badgeClass() } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.sentenceCase(
                                          _vm.alertData.status ||
                                            _vm.FALLBACK_MESSAGE.noData
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pb-1 emobg-body-2",
                              attrs: { "data-test-id": "alert_severity-label" },
                            },
                            [_vm._v(" Alert severity ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pt-1",
                              attrs: { "data-test-id": "alert_severity-value" },
                            },
                            [
                              _c(
                                "ui-badge",
                                { attrs: { color: _vm.getAlertColor() } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.sentenceCase(
                                          _vm.alertData.severity ||
                                            _vm.FALLBACK_MESSAGE.noData
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.isCollisionReportEnabled
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "mt-3 pb-1 emobg-body-2" },
                                  [_vm._v(" Collision report ")]
                                ),
                                _c(
                                  "ui-button",
                                  {
                                    attrs: {
                                      face: _vm.FACES.outline,
                                      loading: _vm.isDownloading,
                                    },
                                    on: { clickbutton: _vm.doDownloadPdf },
                                  },
                                  [
                                    _c("ui-icon", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        color: _vm.COLORS.primary,
                                        size: _vm.ICONS_SIZES.small,
                                        icon: _vm.ICONS.download,
                                      },
                                    }),
                                    _c("span", [_vm._v("Export results")]),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pt-2 pb-1 emobg-body-2",
                              attrs: {
                                "data-test-id": "alert_occurred_on-label",
                              },
                            },
                            [_vm._v(" Alert occurred on ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pt-1 mb-3",
                              attrs: {
                                "data-test-id": "alert_occurred_on-value",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatUtc(
                                      _vm.alertData.occurredOn,
                                      _vm.DATE_FORMAT.defaultExtended,
                                      _vm.operatorTimezone
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.alertDetails.mileage
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt-2 pb-1 emobg-body-2",
                                    attrs: {
                                      "data-test-id":
                                        "mileage_at_the_collision-label",
                                    },
                                  },
                                  [_vm._v(" Mileage at the collision ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt-1 mb-3",
                                    attrs: {
                                      "data-test-id":
                                        "mileage_at_the_collision-value",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDistance(
                                            _vm.alertDetails.mileage,
                                            _vm.mileageUnit,
                                            0
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("AlertDetailsComponent", {
                            attrs: { "alert-details": _vm.alertDetails },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.hasMapData
                      ? _c("div", { staticClass: "row" }, [
                          _c("h3", { staticClass: "col-12" }, [
                            _vm._v(" Map "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12 mt-2" },
                            [
                              _c("GoogleMapZonesComponent", {
                                ref: "alertGmap",
                                staticClass:
                                  "mt-2 emobg-border-1 emobg-border-color-none",
                                attrs: {
                                  "data-test-id": "map",
                                  "map-type-control": false,
                                  zones: _vm.concat(
                                    _vm.alertDetails.map.geofences,
                                    _vm.alertVehicleRoute
                                  ),
                                  "street-view-control": false,
                                  "full-screen-control": "",
                                  "is-static": "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "col-12 mt-2 emobg-color-ink-light",
                            },
                            [
                              _vm._v(
                                "Showing known Vehicle Positions 10 minutes before and after the GPS crossing point"
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.isCollisionReportEnabled
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("CollisionMapAndChart", {
                              attrs: { timestamp: _vm.ocurredOnTimestamp },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-4 p-3 emobg-background-color-ground-lightest",
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "mt-2 mb-5 pb-3 emobg-border-bottom-2 emobg-border-color-ground-light",
                      },
                      [_vm._v(" Comments ")]
                    ),
                    _c("CommentsComponent", {
                      attrs: {
                        "can-comment": _vm.includes(
                          _vm.permissions,
                          _vm.CARRENTAL_PERMISSIONS.alertsComment
                        ),
                        "can-edit": false,
                        comments: _vm.commentsData,
                        "is-loading": _vm.areCommentsLoading,
                        author: _vm.author,
                        "data-test-id": "comments",
                      },
                      on: {
                        addComment: _vm.buildPayload,
                        deleteComment: _vm.remove,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.isCollisionReportEnabled
              ? _c(
                  "div",
                  {
                    staticClass:
                      "AlertsDetailsView__pdfWrapper position-absolute",
                  },
                  [
                    _c("CollisionReportPdfTemplate", {
                      attrs: { timestamp: _vm.ocurredOnTimestamp },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }