<script>
import omit from 'lodash/omit';
import toLower from 'lodash/toLower';
import endsWith from 'lodash/endsWith';
import includes from 'lodash/includes';
import size from 'lodash/size';
import snakeCase from 'lodash/snakeCase';
import { sentenceCase } from '@emobg/web-utils';

import { mileageUnitMixin } from '../../../mixins';
import { ALERT_DETAILS_LABELS, ALERT_TRIGGERS_LABELS } from '../../const/alertData.const';

export default {
  name: 'AlertDetailsComponent',
  mixins: [mileageUnitMixin],
  props: {
    alertDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    alertPayloadLength() {
      return size(this.transformedAlertPayload());
    },
  },
  created() {
    this.ALERT_TRIGGERS_LABELS = ALERT_TRIGGERS_LABELS;
    this.ALERT_DETAILS_LABELS = ALERT_DETAILS_LABELS;
    this.displayExceptions = ['map', 'graph', 'mileage'];
  },
  methods: {
    transformedAlertPayload() {
      return omit(this.alertDetails, this.displayExceptions);
    },
    endsWith,
    includes,
    toLower,
    snakeCase,
    sentenceCase,
  },
};
</script>

<template>
  <div
    data-test-id="alert_details-component"
    class="AlertDetailsComponent"
  >
    <div
      v-for="(value, label, index) in transformedAlertPayload()"
      :key="index"
    >
      <div
        :data-test-id="`${snakeCase(label)}-label`"
        class="py-2 emobg-body-2"
      >
        {{ sentenceCase(ALERT_DETAILS_LABELS[label] || label) }}
      </div>
      <div
        :data-test-id="`${snakeCase(value)}-value`"
        :class="{ 'pb-3': index !== alertPayloadLength - 1 }"
      >
        <template v-if="endsWith(label, 'trigger')">
          {{ ALERT_TRIGGERS_LABELS[toLower(value)] }}
        </template>
        <template v-else-if="endsWith(label, 'magnitude')">
          {{ value ? `${value}G` : FALLBACK_MESSAGE.noData }}
        </template>
        <template v-else-if="endsWith(label, 'direction')">
          {{ value ? `${value}°` : FALLBACK_MESSAGE.noData }}
        </template>
        <template v-else-if="endsWith(label, 'position')">
          <a
            :href="`http://maps.google.com?q=${value.latitude},${value.longitude}`"
            target="=_blank"
            class="emobg-color-primary text-decoration-none"
          >See GPS coordinates</a>
        </template>
        <template v-else-if="includes(toLower(label), 'mileage')">
          {{ formatDistance(value, mileageUnit, 0) }}
        </template>
        <template v-else>
          {{ sentenceCase(value) }}
        </template>
      </div>
    </div>
  </div>
</template>
