var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RouterLink",
    {
      staticClass: "emobg-link-primary emobg-body-2",
      attrs: {
        "data-test-id": "alert-link",
        to: {
          name: _vm.carrental.vehicles.alerts.detail,
          params: {
            vehicleVin: _vm.result.vin,
            alertUuid: _vm.result.objectID,
          },
        },
      },
    },
    [_vm._v(" " + _vm._s(_vm.alertName) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }