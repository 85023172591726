import concat from 'lodash/concat';
import constant from 'lodash/constant';
import filter from 'lodash/filter';
import last from 'lodash/last';
import times from 'lodash/times';
import { DISTANCE_UNIT } from '@emobg/web-utils';
import { CHART_CURVES } from '../../../const/chart.const';

export const getSpeedChartOptions = (speedTelemetry, mileageUnit, timeStamp) => {
  const beforeCollisionData = filter(speedTelemetry, item => item.timestamp <= timeStamp) || [];
  const afterCollisionData = filter(speedTelemetry, item => item.timestamp > timeStamp) || [];
  const speedLabel = `${mileageUnit || DISTANCE_UNIT.kilometers}/h`;

  const fillArray = (speedValues, isStart = false) => {
    const totalLength = speedTelemetry.length;

    return isStart
      ? concat(times((totalLength - speedValues.length), constant(null)), speedValues)
      : concat(speedValues, times((totalLength - speedValues.length), constant(null)));
  };

  return {
    series: [
      {
        name: `Pre-collision speed [${speedLabel}]`,
        data: fillArray(beforeCollisionData.map(item => item && item.value)),
      }, {
        name: `Post-collision speed [${speedLabel}]`,
        data: fillArray([last(beforeCollisionData), ...afterCollisionData].map(item => item && item.value), true),
      }],
    chart: {
      height: 500,
      type: 'line',
      background: '#F9F9F9',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#408E4B', '#EB3323'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      fontWeight: 600,
      height: 50,
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 70,
        height: 5,
        radius: 5,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 10,
      },
    },
    stroke: {
      width: [5, 5],
      curve: CHART_CURVES.smooth,
    },
    yaxis: {
      labels: {
        formatter: mileage => `${mileage} ${mileageUnit}/h`,
      },
    },
    labels: speedTelemetry.map(item => (`${(item.timestamp - timeStamp)}s`)),
  };
};

