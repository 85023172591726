<script>
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import size from 'lodash/size';
import camelCase from 'lodash/camelCase';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList, MuiModal } from '@emobg/motion-ui/v1';

import {
  DELAY,
  exportFile,
  navigationErrorHandler,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { alertListFacets, contentCells } from './config/algoliaTableConfig';
import { ALERT_FILTERS_NAME, OPERATORS } from './const/alertData.const';
import { carRentalErrors } from '../const/errors';

export default {
  name: 'AlertsView',
  components: { MuiAlgoliaList, MuiModal },
  beforeRouteLeave(to, _from, next) {
    if (to && this.isExportStarted && !this.isForcedNavigation) {
      this.urlName = to;
      this.isModalOpen = true;
    } else {
      next();
    }
  },
  props: {
    isVehicleSummaryTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
      isForcedNavigation: false,
      isExportLoading: false,
      urlName: '',
      modalConfig: {
        title: 'Exporting alert list',
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.alert.details, {
      alertsCsvData: state => get(state, 'alertsCsvData.data') || {},
      isExportStarted: state => state.alertsCsvData.STATUS.LOADING,
      hasExportFailed: state => state.alertsCsvData.STATUS.ERROR,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    tableFilter() {
      return this.isVehicleSummaryTab ? `vin: ${get(this, '$route.params.vehicleVin')}` : `operator_uuid: ${this.activeOperatorUuid}`;
    },
  },
  watch: {
    hasExportFailed(newValue) {
      if (newValue) {
        this.throwError({ message: carRentalErrors.alertsExportFailed, delay: DELAY.extraLong });
      }
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.alert.details, ['getAlertsExport']),
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, ['throwError']),
    async exportCsv(activeFilters = {}) {
      this.isExportLoading = true;

      this.$notify({
        message: 'The vehicle list export has started. This process might take a while.',
        delay: DELAY.extraLong,
        type: NOTIFICATION_TYPES.info,
      });

      /* eslint-disable no-param-reassign */
      const algoliaFilters = reduce(
        activeFilters,
        (_filters, filterValues, filterName) => {
          each(filterValues, filterValue => {
            const filterNumericalOperator = get(filterValue, 'numericalOperator');
            if (filterNumericalOperator) {
              const numericalFilterName = filterNumericalOperator === OPERATORS.greaterOrEqual ? ALERT_FILTERS_NAME.occurredOnStartDate : ALERT_FILTERS_NAME.occurredOnEndDate;
              const timestampValue = get(filterValue, 'value');
              _filters[numericalFilterName] = moment.unix(timestampValue).toISOString();
            } else {
              _filters[camelCase(filterName)] = map(filterValues, 'value').join(',');
            }
          });
          return _filters;
        },
        {},
      );
      const filters = {
        operatorId: this.activeOperatorUuid,
        timeZone: this.operatorTimezone,
        ...algoliaFilters,
      };
      await this.getAlertsExport({ filters });

      if (!this.hasExportFailed && !size(this.alertsCsvData)) {
        this.throwError({ message: carRentalErrors.empty, delay: DELAY.extraLong });
        this.isExportLoading = false;
        return;
      }
      if (!this.hasExportFailed) {
        const filename = moment().format('[Alerts_list_]YYYY-MM-DD_HH[h]mm[m]SS[s.csv]');
        exportFile(this.alertsCsvData, filename);
      }
      this.isExportLoading = false;
    },
    navigate() {
      this.isModalOpen = false;
      this.isForcedNavigation = true;
      this.$router.push(this.urlName).catch(navigationErrorHandler);
    },
    contentCells,
    alertListFacets,
  },
};
</script>

<template>
  <div
    :class="`${isVehicleSummaryTab
      ? 'SummaryAlertsView emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4'
      : 'AlertsView px-2 px-lg-5'
    }`"
    :data-test-id="`${isVehicleSummaryTab ? 'summary_' : ''}alerts-view`"
  >
    <h2 :class="`${isVehicleSummaryTab ? 'pb-3 mb-2 emobg-border-bottom-2 emobg-border-color-ground-light' : 'pb-4'}`">
      Alerts
    </h2>
    <MuiAlgoliaList
      :export-columns="contentCells({ operatorTimezone })"
      :facets="alertListFacets({ operatorTimezone })"
      :filters="tableFilter"
      :table-config="contentCells({ operatorTimezone })"
      :is-export-button-loading="isExportLoading"
      is-export-enabled
      data-test-id="list"
      index="vt_carrental_alert_list"
      search-placeholder="Search by alert type or registration number"
      export-button-label="Export first 15000 results"
      @on:export="exportCsv"
    />
    <MuiModal
      v-model="isModalOpen"
      data-test-id="leave_page_feedback-modal"
      v-bind="modalConfig"
      @close-modal="isModalOpen = false"
    >
      <div slot="body">
        <div
          data-test-id="warning"
          class="py-3"
        >
          If you leave the page the export may be interrupted
        </div>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          data-test-id="cancel-button"
          :color="GRAYSCALE.white"
          class="emobg-color-white-contrast"
          @clickbutton="isModalOpen = false"
        >
          Cancel
        </ui-button>
        <ui-button
          data-test-id="navigate-button"
          class="ml-4"
          @clickbutton="navigate()"
        >
          OK
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>
