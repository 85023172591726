<script>
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import carrental from '../../router/CarrentalRouterMap';

export default {
  name: 'AlertLink',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    alertName() {
      return this.result.alert_name || FALLBACK_MESSAGE.dash;
    },
  },
  created() {
    this.carrental = carrental;
  },
};
</script>
<template>
  <RouterLink
    data-test-id="alert-link"
    :to="{
      name: carrental.vehicles.alerts.detail,
      params: {
        vehicleVin: result.vin,
        alertUuid: result.objectID
      }
    }"
    class="emobg-link-primary emobg-body-2"
  >
    {{ alertName }}
  </RouterLink>
</template>
