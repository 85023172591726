var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "AlertDetailsComponent",
      attrs: { "data-test-id": "alert_details-component" },
    },
    _vm._l(_vm.transformedAlertPayload(), function (value, label, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            staticClass: "py-2 emobg-body-2",
            attrs: { "data-test-id": `${_vm.snakeCase(label)}-label` },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.sentenceCase(_vm.ALERT_DETAILS_LABELS[label] || label)
                ) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          {
            class: { "pb-3": index !== _vm.alertPayloadLength - 1 },
            attrs: { "data-test-id": `${_vm.snakeCase(value)}-value` },
          },
          [
            _vm.endsWith(label, "trigger")
              ? [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ALERT_TRIGGERS_LABELS[_vm.toLower(value)]) +
                      " "
                  ),
                ]
              : _vm.endsWith(label, "magnitude")
              ? [
                  _vm._v(
                    " " +
                      _vm._s(
                        value ? `${value}G` : _vm.FALLBACK_MESSAGE.noData
                      ) +
                      " "
                  ),
                ]
              : _vm.endsWith(label, "direction")
              ? [
                  _vm._v(
                    " " +
                      _vm._s(
                        value ? `${value}°` : _vm.FALLBACK_MESSAGE.noData
                      ) +
                      " "
                  ),
                ]
              : _vm.endsWith(label, "position")
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "emobg-color-primary text-decoration-none",
                      attrs: {
                        href: `http://maps.google.com?q=${value.latitude},${value.longitude}`,
                        target: "=_blank",
                      },
                    },
                    [_vm._v("See GPS coordinates")]
                  ),
                ]
              : _vm.includes(_vm.toLower(label), "mileage")
              ? [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatDistance(value, _vm.mileageUnit, 0)) +
                      " "
                  ),
                ]
              : [_vm._v(" " + _vm._s(_vm.sentenceCase(value)) + " ")],
          ],
          2
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }