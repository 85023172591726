var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `${
        _vm.isVehicleSummaryTab
          ? "SummaryAlertsView emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4"
          : "AlertsView px-2 px-lg-5"
      }`,
      attrs: {
        "data-test-id": `${
          _vm.isVehicleSummaryTab ? "summary_" : ""
        }alerts-view`,
      },
    },
    [
      _c(
        "h2",
        {
          class: `${
            _vm.isVehicleSummaryTab
              ? "pb-3 mb-2 emobg-border-bottom-2 emobg-border-color-ground-light"
              : "pb-4"
          }`,
        },
        [_vm._v(" Alerts ")]
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentCells({
            operatorTimezone: _vm.operatorTimezone,
          }),
          facets: _vm.alertListFacets({
            operatorTimezone: _vm.operatorTimezone,
          }),
          filters: _vm.tableFilter,
          "table-config": _vm.contentCells({
            operatorTimezone: _vm.operatorTimezone,
          }),
          "is-export-button-loading": _vm.isExportLoading,
          "is-export-enabled": "",
          "data-test-id": "list",
          index: "vt_carrental_alert_list",
          "search-placeholder": "Search by alert type or registration number",
          "export-button-label": "Export first 15000 results",
        },
        on: { "on:export": _vm.exportCsv },
      }),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "leave_page_feedback-modal" },
            on: {
              "close-modal": function ($event) {
                _vm.isModalOpen = false
              },
            },
            model: {
              value: _vm.isModalOpen,
              callback: function ($$v) {
                _vm.isModalOpen = $$v
              },
              expression: "isModalOpen",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "div",
              { staticClass: "py-3", attrs: { "data-test-id": "warning" } },
              [_vm._v(" If you leave the page the export may be interrupted ")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "emobg-color-white-contrast",
                  attrs: {
                    "data-test-id": "cancel-button",
                    color: _vm.GRAYSCALE.white,
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isModalOpen = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "ui-button",
                {
                  staticClass: "ml-4",
                  attrs: { "data-test-id": "navigate-button" },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.navigate()
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }