var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CollisionReportPdfTemplate emobg-background-color-white px-2 pb-3",
      attrs: { id: _vm.COLLISION_REPORT_PDF_ID },
    },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "col-md-3 px-4" }, [
          _c("h4", { staticClass: "mb-3" }, [_vm._v(" Vehicle details ")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" VIN "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionVehicleData.vin ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Registration number "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionVehicleData.licensePlate ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Model "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionVehicleData.model ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Mileage at the collision "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatDistance(
                      _vm.collisionDetails.mileage,
                      _vm.mileageUnit,
                      0
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-3 ml-3 ml-md-0" }, [
          _c("h4", { staticClass: "mb-3" }, [_vm._v(" Collision details ")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Date and time "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionAlertData.occurredOn
                      ? _vm.formatUtc(
                          _vm.collisionAlertData.occurredOn,
                          _vm.DATE_FORMAT.defaultExtended,
                          _vm.operatorTimezone
                        )
                      : _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Magnitude "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionDetails.magnitude
                      ? `${_vm.collisionDetails.magnitude}G`
                      : _vm.FALLBACK_MESSAGE.noData
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "mb-3 col-6 col-md-12" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Direction "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.collisionDetails.direction
                      ? `${_vm.collisionDetails.direction}°`
                      : _vm.FALLBACK_MESSAGE.noData
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-6 ml-3 ml-md-0" }, [
          _c("h4", { staticClass: "mb-3" }, [_vm._v(" Movement details ")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mb-3" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" RA number "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.vehicleMovementData.rentalAgreementNumber ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "col-6 mb-3" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Movement ID "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.vehicleMovementData.id ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "col-6 mb-3" }, [
              _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                _vm._v(" Type of movement "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.vehicleMovementData.type ||
                      _vm.FALLBACK_MESSAGE.notAvailable
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-6 mb-3" },
              [
                _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                  _vm._v(" Pickup "),
                ]),
                !_vm.get(_vm.vehicleMovementData, "startTime") &&
                _vm.isEmpty(
                  _vm.get(_vm.vehicleMovementData, "startLocation", {})
                )
                  ? [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " ")]
                  : [
                      _c("div", { staticClass: "mb-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatUtc(
                                _vm.vehicleMovementData.startTime,
                                _vm.DATE_FORMAT.defaultExtended,
                                _vm.operatorTimezone
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getLocationLabel(
                              _vm.get(_vm.vehicleMovementData, "startLocation")
                            )
                          ) +
                          " "
                      ),
                    ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-3" },
              [
                _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                  _vm._v(" Return "),
                ]),
                !_vm.get(_vm.vehicleMovementData, "endTime") &&
                _vm.isEmpty(_vm.get(_vm.vehicleMovementData, "endLocation", {}))
                  ? [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " ")]
                  : [
                      _c("div", { staticClass: "mb-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatUtc(
                                _vm.vehicleMovementData.endTime,
                                _vm.DATE_FORMAT.defaultExtended,
                                _vm.operatorTimezone
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getLocationLabel(
                              _vm.get(_vm.vehicleMovementData, "endLocation")
                            )
                          ) +
                          " "
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row px-3 mt-5" },
        [
          _c("CollisionMapAndChart", {
            attrs: { timestamp: _vm.timestamp, "is-pdf": "" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-12 emobg-color-white emobg-background-color-corporate-primary-darker p-2 mb-1 pl-4",
      },
      [_c("h3", [_vm._v("Collision report")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 p-4 mb-2" }, [
      _c("img", {
        attrs: {
          src: "/carrental/logo.svg",
          width: "100px",
          height: "50px",
          alt: "logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }