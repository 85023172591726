<script>
import get from 'lodash/get';
import inRange from 'lodash/inRange';
import isNil from 'lodash/isNil';

import { mapGetters, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mileageUnitMixin } from '../../../mixins';
import { getCollisionPoints } from '../const/collision.const';
import ChartComponent from '../../../components/ChartComponent';
import GoogleMapTripsComponent from '../../../Trip/Details/components/GoogleMapTripsComponent';
import { getSpeedChartOptions } from '../config/speedChart.config';

export default {
  name: 'CollisionMapAndChart',
  components: {
    ChartComponent,
    GoogleMapTripsComponent,
  },
  mixins: [mileageUnitMixin],
  props: {
    timestamp: {
      type: String,
      required: true,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.alert.details, {
      collisionAlertData: state => state.alertData.data || {},
    }),
    ...mapGetters(DOMAINS_MODEL.carRental.alert.details, {
      collisionDetails: 'alertDetails',
      mapPaths: 'mapPaths',
    }),
    speedTelemetry() {
      const speedTimeseries = get(this.collisionDetails, 'graph.timeseries', []);

      return speedTimeseries.map(speedData => ({
        ...speedData,
        value: this.formatDistance(speedData.value, this.mileageUnit),
      }));
    },
    hasMapData() {
      return this.mapPaths.before.length || this.mapPaths.after.length;
    },
    speedChartOptions() {
      return getSpeedChartOptions(this.speedTelemetry, this.mileageUnit, this.timestamp);
    },
  },
  methods: {
    inRange,
    isNil,
    get,
    getCollisionPoints,
  },
};
</script>

<template>
  <div
    class="CollisionMapAndChart col-12"
    data-test-id="collision_map_chart-component"
  >
    <div class="row mb-2">
      <div class="col-12">
        <div class="emobg-body-2">
          Location and collision bearing
        </div>
      </div>
    </div>
    <div class="row no-gutters emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-none py-2 px-3">
      <div class="col-md-6 pr-3">
        <div class="emobg-border-4 emobg-border-color-ground-lighter emobg-border-radius-none">
          <template v-if="hasMapData">
            <GoogleMapTripsComponent
              id="map"
              ref="collisionMap"
              :map-type-control="false"
              :street-view-control="false"
              :zoom-control="false"
              :before-path="mapPaths.before"
              :after-path="mapPaths.after"
              :marker-sources="getCollisionPoints(collisionDetails, mapPaths.before, mapPaths.after)"
              data-test-id="location_crash-map"
              class="w-100"
            />
          </template>
          <div
            v-else
            class="px-auto d-flex justify-content-center align-items-center GoogleMapComponent__holder"
          >
            {{ FALLBACK_MESSAGE.notAvailable }}
          </div>
        </div>
        <div class="py-2 emobg-color-ink-light">
          Location of the car 30” before the collision (green) and 30” after the collision (red)
        </div>
      </div>
      <div class="col-md-6">
        <div
          v-if="get(collisionDetails, 'direction')"
          class="emobg-border-4 emobg-border-color-ground-lighter emobg-border-radius-none"
        >
          <div class="row">
            <div class="col-4" />
            <div class="col-4 pt-1 d-flex justify-content-center">
              <div
                :class="[
                  'p-2 mb-6 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white',
                  {
                    invisible: !inRange(collisionDetails.direction, 315, 361) && !inRange(collisionDetails.direction, 0, 44),
                  },
                ]"
              >
                Front side impact
              </div>
            </div>
            <div class="col-4" />
          </div>
          <div class="row">
            <div class="col-4 d-flex justify-content-start pl-6 my-auto">
              <span
                :class="[
                  'p-2 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white',
                  {
                    invisible: !inRange(collisionDetails.direction, 225, 314),
                  },
                ]"
              >
                Left side impact
              </span>
            </div>
            <div class="col-4">
              <div
                class="orbit"
                :style="{'transform' : `rotate(${collisionDetails.direction + 45}deg)`}"
              >
                <div
                  class="orbit-cirlce"
                  :class="{'disabled': isNil(collisionDetails.direction) }"
                >
                  <img
                    src="/carrental/collision-car.svg"
                    alt="Collision side"
                    height="157px"
                    :style="{'transform' : `rotate(-${collisionDetails.direction + 45}deg)`}"
                  >
                </div>
              </div>
            </div>
            <div class="col-4 d-flex justify-content-end pr-6 my-auto">
              <span
                :class="[
                  'p-2 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white',
                  {
                    invisible: !inRange(collisionDetails.direction, 45, 134),
                  },
                ]"
              >
                Right side impact
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-4" />
            <div class="col-4 pb-1 d-flex justify-content-center">
              <span
                :class="[
                  'p-2 mt-6 emobg-border-radius-small emobg-background-color-secondary-dark emobg-color-white',
                  {
                    invisible: !inRange(collisionDetails.direction, 135, 224),
                  },
                ]"
              >
                Rear side impact
              </span>
            </div>
            <div class="col-4" />
          </div>
        </div>
        <div class="py-2 emobg-color-ink-light">
          <span
            v-if="collisionDetails && isNil(collisionDetails.direction)"
            class="emobg-color-danger"
          > We could not determine the side where the car was impacted </span>
          <template v-else>
            Collision bearing (The dot shows the estimated direction of the impact)
          </template>
        </div>
      </div>
    </div>
    <div class="row mb-2 mt-4">
      <div class="col-12">
        <div class="emobg-body-2">
          Speed graph
        </div>
      </div>
    </div>

    <div class="row no-gutters emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-none py-3 mt-2 justify-content-center">
      <div
        v-if="speedTelemetry.length"
        class="col-12"
      >
        <ChartComponent
          :id="`speed${isPdf ? '-pdf' : ''}`"
          :chart-options="speedChartOptions"
          class="w-100 p-2"
          data-test-id="speed-chart"
        />
        <div class="py-2 emobg-color-ink-light d-flex justify-content-center">
          Chart showing the speed over 30 seconds before and after the collision
        </div>
      </div>
      <div
        v-else
        class="px-auto py-6"
      >
        We could not get any speed data because the car was not in movement when the accident occurred
      </div>
    </div>
  </div>
</template>
