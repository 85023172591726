import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';

export const COLLISION_MAP_PDF_ZOOM = 14;

export const COLLISION_REPORT_PDF_ID = 'pdfContent';

export const getCollisionPoints = (collisionDetails, mapBefore, mapAfter) => ([
  {
    latitude: first(mapBefore) && first(mapBefore).lat,
    longitude: first(mapBefore) && first(mapBefore).lng,
    uuid: 'Before',
    tooltip: 'Pre-crash',
    icon: { url: '/carrental/collision_vehicle_before.svg' },
  },
  {
    latitude: get(collisionDetails, 'position.latitude'),
    longitude: get(collisionDetails, 'position.longitude'),
    zIndex: 10000,
    uuid: 'Collision',
    tooltip: 'Collision',
    icon: { url: '/carrental/collision.svg' },
  },
  {
    latitude: last(mapAfter) && last(mapAfter).lat,
    longitude: last(mapAfter) && last(mapAfter).lng,
    uuid: 'After',
    tooltip: 'Post-crash',
    icon: { url: '/carrental/collision_vehicle_after.svg' },
  },
]);
