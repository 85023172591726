import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export const getLocationLabel = location => {
  const locationId = get(location, 'id', '');
  const locationName = get(location, 'name', '');
  const locationNameLabel = locationName ? `- ${locationName}` : '';
  return !isEmpty(location)
    ? `${locationId} ${locationNameLabel}`
    : FALLBACK_MESSAGE.noData;
};
