<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapGetters, mapState } from 'vuex';

import { DATE_FORMAT, formatUtc, TIME_ZONE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mileageUnitMixin } from '../../../mixins';
import CollisionMapAndChart from './CollisionMapAndChart';
import { COLLISION_REPORT_PDF_ID } from '../const/collision.const';
import { getLocationLabel } from '../../utils/location';

export default {
  name: 'CollisionReportPdfTemplate',
  components: {
    CollisionMapAndChart,
  },
  mixins: [mileageUnitMixin],
  props: {
    timestamp: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carRental.alert.details, {
      collisionAlertData: state => state.alertData.data || {},
    }),
    ...mapGetters(DOMAINS_MODEL.carRental.alert.details, {
      collisionDetails: 'alertDetails',
      collisionVehicleData: 'alertVehicleData',
      vehicleMovementData: 'vehicleMovementData',
    }),
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.COLLISION_REPORT_PDF_ID = COLLISION_REPORT_PDF_ID;
  },
  methods: {
    isEmpty,
    get,
    formatUtc,
    getLocationLabel,
  },
};
</script>

<template>
  <div
    :id="COLLISION_REPORT_PDF_ID"
    class="CollisionReportPdfTemplate emobg-background-color-white px-2 pb-3"
  >
    <div class="row">
      <div class="col-12 emobg-color-white emobg-background-color-corporate-primary-darker p-2 mb-1 pl-4">
        <h3>Collision report</h3>
      </div>
      <div class="col-12 p-4 mb-2">
        <img
          src="/carrental/logo.svg"
          width="100px"
          height="50px"
          alt="logo"
        >
      </div>
      <div class="col-md-3 px-4">
        <h4 class="mb-3">
          Vehicle details
        </h4>
        <div class="row">
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              VIN
            </div>
            {{ collisionVehicleData.vin || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Registration number
            </div>
            {{ collisionVehicleData.licensePlate || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Model
            </div>
            {{ collisionVehicleData.model || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Mileage at the collision
            </div>
            {{ formatDistance(collisionDetails.mileage, mileageUnit, 0) }}
          </div>
        </div>
      </div>
      <div class="col-md-3 ml-3 ml-md-0">
        <h4 class="mb-3">
          Collision details
        </h4>
        <div class="row">
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Date and time
            </div>
            {{ collisionAlertData.occurredOn
              ? formatUtc(collisionAlertData.occurredOn, DATE_FORMAT.defaultExtended, operatorTimezone)
              : FALLBACK_MESSAGE.notAvailable
            }}
          </div>
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Magnitude
            </div>
            {{ collisionDetails.magnitude ? `${collisionDetails.magnitude}G` : FALLBACK_MESSAGE.noData }}
          </div>
          <div class="mb-3 col-6 col-md-12">
            <div class="mb-1 emobg-body-2">
              Direction
            </div>
            {{ collisionDetails.direction ? `${collisionDetails.direction}°` : FALLBACK_MESSAGE.noData }}
          </div>
        </div>
      </div>
      <div class="col-md-6 ml-3 ml-md-0">
        <h4 class="mb-3">
          Movement details
        </h4>
        <div class="row">
          <div class="col-12 mb-3">
            <div class="mb-1 emobg-body-2">
              RA number
            </div>
            {{ vehicleMovementData.rentalAgreementNumber || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="col-6 mb-3">
            <div class="mb-1 emobg-body-2">
              Movement ID
            </div>
            {{ vehicleMovementData.id || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="col-6 mb-3">
            <div class="mb-1 emobg-body-2">
              Type of movement
            </div>
            {{ vehicleMovementData.type || FALLBACK_MESSAGE.notAvailable }}
          </div>
          <div class="col-6 mb-3">
            <div class="mb-1 emobg-body-2">
              Pickup
            </div>
            <template v-if="!get(vehicleMovementData, 'startTime') && isEmpty(get(vehicleMovementData, 'startLocation', {}))">
              {{ FALLBACK_MESSAGE.noData }}
            </template>
            <template v-else>
              <div class="mb-1">
                {{ formatUtc(vehicleMovementData.startTime, DATE_FORMAT.defaultExtended, operatorTimezone) }}
              </div>
              {{ getLocationLabel(get(vehicleMovementData, 'startLocation')) }}
            </template>
          </div>
          <div class="col-6 mb-3">
            <div class="mb-1 emobg-body-2">
              Return
            </div>
            <template v-if="!get(vehicleMovementData, 'endTime') && isEmpty(get(vehicleMovementData, 'endLocation', {}))">
              {{ FALLBACK_MESSAGE.noData }}
            </template>
            <template v-else>
              <div class="mb-1">
                {{ formatUtc(vehicleMovementData.endTime, DATE_FORMAT.defaultExtended, operatorTimezone) }}
              </div>
              {{ getLocationLabel(get(vehicleMovementData, 'endLocation')) }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 mt-5">
      <CollisionMapAndChart
        :timestamp="timestamp"
        is-pdf
      />
    </div>
  </div>
</template>
